
import { Button, Col, Row } from 'antd';
import { ClearOutlined } from '@ant-design/icons';
const LogComponent = ({ log, onClearLog }) => {
    return (
        <Row className='mt-2'>
            <Col span={24} className='logs-header'>
                <div className='title'><b>Application Logs</b></div>
                <div className='clear'><Button type="dashed" icon={<ClearOutlined />} onClick={onClearLog}>CLEAR LOGS</Button></div>
            </Col>
            <Col span={24} className='mt-2'>
                <div className='logs ml-2 mr-2' style={{ overflow: 'auto', minHeight: '350px', maxHeight: '350px', padding: '5px 10px' }} >
                    {log?.map((item, index) => {
                        return (
                            <span>
                                <span className={item.status === 1 ? 'text-success' : item.status === 0 ? 'text-danger' : 'text-info'}><b>{item.time + ': '}</b></span>
                                {item.type === 1 ? (
                                    <a className={item.status === 1 ? 'text-success' : item.status === 0 ? 'text-danger' : 'text-info'
                                    } key={index} href={`https://solscan.io/tx/${item.value}`} target='_blank'  rel="noreferrer"><span>{item.prefix ? item.prefix + ': ' : ''}{item.value}</span></a>)
                                    :
                                    (
                                        <span className={item.status === 1 ? 'text-success' : item.status === 0 ? 'text-danger' : 'text-info'
                                        } key={index}><span>{item.prefix ? item.prefix + ': ' : ''}{item.value}</span></span>
                                    )}
                            </span>
                        );
                    })}
                </div>
            </Col>
        </Row >);
};


export default LogComponent;