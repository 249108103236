import { useState, useEffect } from 'react';
import { isDesktop } from 'react-device-detect';
import { Modal } from 'antd';
import LogComponent from './components/Log';
import MarketMakerComponent from './components/MarketMaker';
import BulkSenderComponent from './components/BulkSender';
import HeaderComponent from './components/Header';
import { Route, Routes } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

function App() {
  const [clientId] = useState(uuidv4());
  const [log, setLog] = useState([]);
  useEffect(() => {
    const eventSource = new EventSource(`https://server.solmarketmaker.com/events?clientId=${clientId}`);

    eventSource.onmessage = async (event) => {
      const data = JSON.parse(event.data);
      console.log(data);
      let time = new Date(data.time).toLocaleTimeString();
      let newItem = {
        ...data,
        time
      }
      setLog(prevLog => { return [newItem, ...prevLog] });
      // const prefix = data.prefix;
      // if (prefix === 'Submitting bundle') {
      //   let count = 0;
      //   while (++count < 5) {
      //     const status = await getBundleStatus(data.value);
      //     if (!status) {
      //       if (count === 4) {
      //         newItem = {
      //           ...newItem,
      //           status: 0,
      //           prefix: 'Bundle has been failed',
      //         }
      //         setLog(prevLog => { return [newItem, ...prevLog] });
      //       }
      //       await new Promise(resolve => setTimeout(resolve, 500));
      //     }
      //     else {
      //       newItem = {
      //         ...newItem,
      //         status: 1,
      //         prefix: 'Bundle has been confirmed successfully',
      //       }
      //       setLog(prevLog => { return [newItem, ...prevLog] });
      //       break;
      //     }
      //   }
      // }
    };

    eventSource.onerror = (error) => {
      console.error('EventSource failed:', error);
      eventSource.close();
    };

    return () => {
      eventSource.close();
    };
  }, [clientId]);

  // useEffect(() => {
  //   var socket = io('https://server.solmarketmaker.com', { transports: ['websocket'] });
  //   socket.on('connect', () => {
  //     // console.log('Connected to server', socket.id);
  //     setSocketId(socket.id);
  //   });

  //   socket.on('data', async data => {
  //     let time = new Date(data.time).toLocaleTimeString();
  //     let newItem = {
  //       ...data,
  //       time
  //     }
  //     setLog(prevLog => { return [newItem, ...prevLog] });
  //     // const prefix = data.prefix;

  //     //   }
  //     // }
  //   });

  //   return () => socket.disconnect();
  // }, []);

  async function newLog(newLog) {
    setLog([newLog, ...log]);
  }

  async function newServerLog(newLog) {
    let time = new Date(newLog.time).toLocaleTimeString();
    let newItem = {
      ...newLog,
      time
    }
    setLog(prevLog => { return [newItem, ...prevLog] });
  }

  async function clearLog() {
    setLog([]);
  }

  async function getBundleStatus(bundle) {
    let body = {
      "jsonrpc": "2.0",
      "id": 1,
      "method": "getBundleStatuses",
      "params": [
        [
          bundle
        ]
      ]
    };
    const response = await fetch('https://mainnet.block-engine.jito.wtf/api/v1/bundles', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }).then(response => response.json());
    console.log(response);
    return response.result.value.length > 0 && response.result.value[0];
  }

  return isDesktop ? (
    <div className='h-100' style={{ paddingLeft: "50px", paddingRight: "50px" }}>
      <HeaderComponent />
      <Routes>
        <Route path="/market-maker" element={<MarketMakerComponent clientId={clientId} onNewLog={newLog} onNewServerLog={newServerLog} />} />
        <Route path="/bulk-sender" element={<BulkSenderComponent clientId={clientId} onNewLog={newLog} onNewServerLog={newServerLog} />} />
      </Routes>
      <LogComponent log={log} onClearLog={clearLog} />
    </div >
  ) : <>
    <Modal
      visible={true}
      maskClosable={false}
      keyboard={false}
      style={{ color: "red" }}
    >
      <div style={{ fontSize: "16px" }}>
        <p>Market Maker is only access by Desktop/PC</p>
      </div>
    </Modal>
  </>
}

export default App;