import { NavLink } from 'react-router-dom';
import { Row, Col } from "antd";

const HeaderCompontent = () => {
    return (
        <Row>
            <Col span={10} offset={7}>
                <Row justify="space-around" className="header">
                    <NavLink to="/market-maker">Market Maker</NavLink>
                    <NavLink to="/bulk-sender">Bulk Sender</NavLink>
                    <NavLink to="/bundle-liquidity">Add Liquidity</NavLink>
                    <NavLink to="/remove-liquidity">Remove Liquidity</NavLink>

                </Row>
            </Col>
        </Row>
    );
};

export default HeaderCompontent;