import { useState, useEffect } from "react";
import { Row, Col, Space, Input, Button, Radio, InputNumber } from "antd";
import { ReloadOutlined, LoadingOutlined, ForwardOutlined, RedoOutlined } from "@ant-design/icons";
import ReactCodeMirror from '@uiw/react-codemirror';
import { useApi, useNotification } from '../hooks';
const BulkSenderComponent = ({ clientId, onNewServerLog, onNewLog }) => {
    const { post } = useApi();
    const { success, error, warning } = useNotification();

    const [publicKeys, setPublicKeys] = useState();

    const [tokenAddress, setTokenAddress] = useState();
    const [senderPrivateKey, setSenderPrivateKey] = useState();
    const [senderPublicKey, setSenderPublicKey] = useState('');
    const [min, setMin] = useState(0);
    const [max, setMax] = useState(0);
    const [tokenInfo, setTokenInfo] = useState({
        tokenAmount: 0,
        name: '',
    });
    const [tip, setTip] = useState(0.0055);
    const [mode, setMode] = useState('fixed');
    const [distributeValue, setDistributeValue] = useState([]);
    const [readyToRun, setReadyToRun] = useState(false);
    const [isRunning, setIsRunning] = useState(false);

    useEffect(() => {
        if (!senderPrivateKey) return;
        getPublicKey();

    }, [senderPrivateKey])

    useEffect(() => {
        if (!tokenAddress) return;
        getTokenInfo();
    }, [senderPublicKey, tokenAddress])

    async function onPublicKeysChange(value) {
        setPublicKeys(value.split('\n'));
    }

    function getResult() {
        let result = '';
        for (let i = 0; i < distributeValue.length; i++) {
            result += `${distributeValue[i].publicKey},${distributeValue[i].amount}\n`;
        }
        return result;
    }

    async function getTokenInfo() {
        var res = await fetch('https://server.solmarketmaker.com/getTokenInfo', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Client-ID': clientId
            },
            body: JSON.stringify({
                publicKey: senderPublicKey,
                mint: tokenAddress
            })
        });
        var data = await res.json();
        setTokenInfo(data);
    }

    async function getPublicKey() {
        var res = await fetch('https://server.solmarketmaker.com/getPublicKey', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Client-ID': clientId
            },
            body: JSON.stringify({
                privateKey: senderPrivateKey
            })
        });
        var data = await res.json();
        setSenderPublicKey(data.publicKey);
    }

    async function onRun() {
        setIsRunning(true);
        const response = await post(clientId, '/distro', {
            senderPrivateKey,
            mint: tokenAddress,
            data: distributeValue,
            tip: tip
        });
        onNewServerLog(response);
        setIsRunning(false);
    }

    function onTest() {
        let result = []
        let sum = 0;
        for (let i = 0; i < publicKeys.length; i++) {
            const distributeValue = generateRandomValue();
            sum += distributeValue;
            result.push({
                publicKey: publicKeys[i],
                amount: distributeValue
            });
        }
        let validToRun = sum <= tokenInfo.tokenAmount;
        setReadyToRun(validToRun);
        if (!validToRun) {
            onNewLog({
                time: new Date().toLocaleTimeString(),
                type: 0,
                value: `Insufficient balance. Please change the amount then regenerate again`,
                status: 0
            });
            return;

        }
        else {
            setDistributeValue(result);
            onNewLog({
                time: new Date().toLocaleTimeString(),
                type: 0,
                value: `${sum.toLocaleString()} ${tokenInfo.name} will be distributed to ${result.length} wallets`,
                prefix: 'Ready to run',
                status: 1
            });
        }
    }
    function generateRandomValue() {
        // Generate random value from min to max
        if (mode === 'fixed') {
            return min;
        } else {
            return Math.floor(Math.random() * (max - min + 1) + min);
        }
    }
    function validateRunButton() {
        if (senderPrivateKey === '' || tokenAddress === '')
            return true;
        if (mode === 'fixed')
            return !min;
        return !min || !max;
    }

    function renderDistributionValue() {
        if (mode === 'fixed') {
            return (
                <Row className="mt-2">
                    <Col span={3}>
                        <b>Amount:</b>
                    </Col>
                    <Col span={10}>
                        <InputNumber value={min} onChange={e => {
                            setMin(e);
                            setMax(e);
                            setDistributeValue([]);
                            setReadyToRun(false);
                        }} style={{ width: "100%" }} placeholder="Amount" />
                    </Col>
                </Row>
            );
        } else {
            return (
                <Row className="mt-2">
                    <Col span={3}>
                        <b>Range:</b>
                    </Col>
                    <Col span={10}>
                        <Row justify="space-between">
                            <Col span={10}>
                                <InputNumber style={{ width: "100%" }} value={min} onChange={e => {
                                    setMin(e);
                                    setDistributeValue([]);
                                    setReadyToRun(false);
                                }} placeholder="Min amount" />
                            </Col>
                            <Col span={10} offset={1}>
                                <InputNumber value={max} onChange={e => {
                                    setMax(e);
                                    setDistributeValue([]);
                                    setReadyToRun(false);
                                }} style={{ width: "100%" }} placeholder="Max amount" />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            );
        }
    }
    return (
        <div className='mt-2'>
            <Row>
                <Col span={11}>
                    <Row>
                        <Col span={3}>
                            <b>Sender key: </b>
                        </Col>
                        <Col span={20}>
                            <Space.Compact
                                style={{
                                    width: '100%',
                                }}
                            >
                                <Input value={senderPrivateKey} placeholder="Please enter sender private key" onChange={e => {
                                    setSenderPrivateKey(e.target.value);
                                    setDistributeValue([]);
                                    setReadyToRun(false);
                                }} />
                            </Space.Compact>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col span={3}>
                            <b>Token mint: </b>
                        </Col>
                        <Col span={20}>
                            <Space.Compact
                                style={{
                                    width: '100%',
                                }}
                            >
                                <Input value={tokenAddress} placeholder="Please enter token address" onChange={e => {
                                    setTokenAddress(e.target.value);
                                    setDistributeValue([]);
                                    setReadyToRun(false);
                                }} />
                                <Button icon={<ReloadOutlined />} type="primary" onClick={getTokenInfo}>Refresh Balance</Button>
                            </Space.Compact>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col span={23} className="action-container-buy">
                            <Row justify="center" className='mt-2'>
                                <h5>Distribution</h5>
                            </Row>
                            <Row>
                                <Col span={22} offset={1}>
                                    <Row>
                                        <Col span={3}>
                                            <b>Sender: </b>
                                        </Col>
                                        <Col span={21}>
                                            <b><label className="text-danger">{senderPublicKey ? senderPublicKey : 'Empty'}</label></b>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={3}>
                                            <b>Amount:</b>
                                        </Col>
                                        <Col span={21}>
                                            <b><label className="text-success">{tokenInfo.tokenAmount.toLocaleString()} {tokenInfo.name}</label></b>
                                        </Col>
                                    </Row>

                                </Col>
                                <Col className="mt-2" span={22} offset={1}>
                                    <Row>
                                        <Col span={3}>
                                            <b>Mode: </b>
                                        </Col>
                                        <Col span={21}>
                                            <Radio.Group value={mode} onChange={e => {
                                                setMode(e.target.value);
                                                setMin(0);
                                                setMax(0);
                                            }}>
                                                <Radio.Button value="fixed">Fixed Amount</Radio.Button>
                                                <Radio.Button value="range">Range</Radio.Button>
                                            </Radio.Group>
                                        </Col>
                                    </Row>
                                    {renderDistributionValue()}
                                    <Row className="mt-2">
                                        <Col span={3}>
                                            <b>Jito tip (SOL):</b>
                                        </Col>
                                        <Col span={10}>
                                            <Row justify="space-between">
                                                <Col span={10}>
                                                    <InputNumber min="0.001" step="0.001" style={{ width: "100%" }} value={tip} onChange={e => {
                                                        setTip(e);
                                                    }} placeholder="Tip" />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="mt-2 mb-2" justify="center" >
                                <Button disabled={validateRunButton() || isRunning} icon={<RedoOutlined />} onClick={onTest} type="primary">Generate </Button>
                                <Button style={{ marginLeft: "1rem" }} disabled={!readyToRun || isRunning} icon={isRunning ? <LoadingOutlined /> : <ForwardOutlined />} onClick={onRun} type="primary">Run</Button>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col span={6}>
                    <b>Recipients <span className="text-danger">(maximum 36 wallets)</span></b>
                    <ReactCodeMirror
                        minHeight='300px'
                        maxHeight='300px'
                        onChange={onPublicKeysChange}
                        value={publicKeys?.join('\n')}
                    />
                </Col>
                <Col span={7}>
                    <b>Result</b>
                    <ReactCodeMirror
                        editable={false}
                        minHeight='300px'
                        maxHeight='300px'
                        onChange={onPublicKeysChange}
                        value={getResult()}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default BulkSenderComponent;